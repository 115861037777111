import * as React from 'react';
import {ContentBoxes, ContentBox, Click, Icon, Table, summon, Toast} from '@pluto-tv/assemble';

import {INestedClipProps} from '../nestedPropsInterface';
import {IClip, ITimedText} from 'models/clips';
import {downloadFile} from 'helpers/downloadFile';

export default ({model}: INestedClipProps): JSX.Element => {
  const downloadFileByType = async (id, uri, fileType = 'cc') => {
    const filename = uri.substring(uri.lastIndexOf('/') + 1);
    const resourceId = fileType === 'audio' ? filename.replace('.wav', '') : id;

    const downloadUrl = `clips/${model.id}/resource/${fileType}/${resourceId}`;

    try {
      const fileData = await summon.get<void, {downloadUrl: string}>(downloadUrl);

      downloadFile(fileData.downloadUrl, filename);
    } catch (e) {
      Toast.error('Error', 'Failed to download file');
    }
  };

  const [timedText, setTimedText] = React.useState<ITimedText[]>([]);

  React.useEffect(() => {
    if (!model) {
      return;
    }

    const tt: ITimedText[] = [];

    const getInfoFromModel = (items: Partial<IClip>[], type: 'cc' | 'subtitle') => {
      items.forEach(item => {
        if (item.uri && item.language) {
          const ttItem = {
            ...item,
            type,
          };

          const date = !!ttItem.updatedAt
            ? new Date(ttItem.updatedAt)
            : new Date(model?.timedText?.find(x => x.uri === ttItem.uri)?.updatedAt || '');

          ttItem.updatedAt = isNaN(date.getTime()) ? '' : date.toLocaleString();

          tt.push(ttItem as ITimedText);
        }
      });
    };

    getInfoFromModel(model.cc || [], 'cc');
    getInfoFromModel(model.subtitles || [], 'subtitle');

    const sortedTT = tt.sort(
      (a: ITimedText, b: ITimedText) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    );

    setTimedText(sortedTT);
  }, [model]);

  return (
    <ContentBoxes layout='stack'>
      <ContentBox title='Captions & Subtitles'>
        <Table
          fixedHeader={true}
          emptyMsg='No Timed Text for this clip'
          cols={[
            {
              label: 'URI',
              field: 'uri',
              colMaxWidth: '22rem',
            },
            {
              label: 'Type',
              colWidth: '10.25rem',
              transform: row => row.type,
            },
            {
              label: 'Language',
              colWidth: '10.25rem',
              transform: row => row.language,
            },
            {
              label: 'Updated At',
              colWidth: '13.25rem',
              transform: row => (!!row.updatedAt ? row.updatedAt : 'N/A'),
            },
            {
              label: 'Actions',
              colWidth: '6.25rem',
              transform: row => (
                <Click onClick={() => downloadFileByType(row.id, row.uri)}>
                  <Icon icon='download' />
                </Click>
              ),
            },
          ]}
          rows={timedText}
        ></Table>
      </ContentBox>
      <ContentBox title='Audio Tracks'>
        <Table
          fixedHeader={true}
          emptyMsg='No Audio Tracks for this clip'
          cols={[
            {
              label: 'URI',
              field: 'uri',
              colMaxWidth: '22rem',
            },
            {
              label: 'Type',
              field: 'audioType',
              colWidth: '10.25rem',
            },
            {
              label: 'Language',
              colWidth: '10.25rem',
              transform: row => row.language,
            },
            {
              label: 'Updated At',
              colWidth: '13.25rem',
              transform: row => (!!row.updatedAt ? new Date(row.updatedAt).toLocaleString() : 'N/A'),
            },
            {
              label: 'Actions',
              colWidth: '6.25rem',
              transform: row => (
                <Click onClick={() => downloadFileByType(row.id, row.uri, 'audio')}>
                  <Icon icon='download' />
                </Click>
              ),
            },
          ]}
          rows={model.discreteAudio}
        ></Table>
      </ContentBox>
    </ContentBoxes>
  );
};
