import * as React from 'react';

import {
  Box,
  Button,
  Center,
  Click,
  Cluster,
  Dialog,
  Heading,
  IValidatorField,
  Icon,
  ImageWrapper,
  Notification,
  Paragraph,
  Popover,
  Spinner,
  Stack,
  Table,
  Template,
  Toast,
  useValidateForm,
} from '@pluto-tv/assemble';

import {maxBy, uniqBy} from 'lodash-es';

import OrderForm from 'components/orderForm';
import {IVodCategoryTitle, IVodEntryOrder} from 'models/vodCategoryEntry';
import {
  useBulkReorderMutation,
  useLazyFindQuery as useFindQuery,
} from 'features/vodCategoryEntries/vodCategoryEntriesApi';
import {vodEntryMapper} from 'helpers/mapVodCategoryEntries';

interface IVodCollectionEntryReorderDialog {
  isOrderDialogOpen: boolean;
  vodCategoryId: string;
  setIsOrderDialogOpen: (open: boolean) => void;
  setHasReordered: (open: boolean) => void;
}

interface IOrderPopover {
  [key: number]: boolean;
}

const DEFAULT_ROWS_PER_PAGE = 250;

interface IVodCategoryEntriesReorder {
  orderElements: IVodEntryOrder[];
}

const reorderValidator: IValidatorField<IVodCategoryEntriesReorder>[] = [
  {
    name: 'orderElements',
    label: 'Entries',
    required: true,
  },
];

export default ({
  isOrderDialogOpen,
  setIsOrderDialogOpen,
  vodCategoryId,
  setHasReordered,
}: IVodCollectionEntryReorderDialog): JSX.Element => {
  const {
    model,
    setFields,
    state: formState,
    setModel,
  } = useValidateForm<IVodCategoryEntriesReorder>(reorderValidator, 'immediate');
  const [getEntries, {data: vodEntries, isFetching: isFetchingEntries}] = useFindQuery();

  const [entries, setEntries] = React.useState<IVodCategoryTitle[]>([]);
  const [orderPopoverOpen, setOrderPopoverOpen] = React.useState<IOrderPopover>({});
  const [highestOrder, setHighestOrder] = React.useState<number | undefined>();
  const [lazyLoading, setLazyLoading] = React.useState<boolean>(false);
  const [offset, setOffset] = React.useState<number>(0);

  const fetchEntries = React.useCallback(
    offsetValue => {
      getEntries({
        id: vodCategoryId!,
        offset: offsetValue,
        limit: DEFAULT_ROWS_PER_PAGE,
        sort: 'order:asc',
      });
    },
    [getEntries, vodCategoryId],
  );

  const handleHasReordered = React.useCallback(
    hasReordered => {
      setHasReordered(hasReordered);
    },
    [setHasReordered],
  );

  const setPristineModel = React.useCallback(
    entries => {
      setModel({
        orderElements: entries.map(e => ({id: e.id, order: e.order})),
      });
    },
    [setModel],
  );

  const [updateVodOrderBulk, {isLoading}] = useBulkReorderMutation();

  const changeRowOrder = (arr: IVodCategoryTitle[]) => {
    return arr.map((item, index) => {
      return {...item, order: index + 1};
    });
  };

  const onDrop = (key, value, orig, dest) => {
    if (!lazyLoading) {
      const newOrder = [...entries];
      const row = newOrder.splice(orig, 1)[0];
      newOrder.splice(dest, 0, row);
      const newEntries = changeRowOrder(newOrder);
      setEntries(newEntries);
      setFields({
        orderElements: newEntries.map(e => ({id: e.id, order: e.order})),
      });
    }
  };

  React.useEffect(() => {
    if (vodCategoryId && isOrderDialogOpen) {
      fetchEntries(offset);
    } else {
      setTimeout(() => {
        setOffset(0);
        setEntries([]);
      }, 400);
    }
  }, [fetchEntries, vodCategoryId, offset, isOrderDialogOpen]);

  React.useEffect(() => {
    if (vodEntries?.data.documents) {
      if (vodEntries?.metadata?.offset === 0) {
        const pageEntries = vodEntryMapper(vodEntries?.data.documents);
        setPristineModel(pageEntries);
        setEntries(pageEntries);
      } else {
        setEntries(old => {
          const pageEntries = uniqBy(old?.concat(vodEntryMapper(vodEntries?.data.documents)), 'id');
          setPristineModel(pageEntries);
          return pageEntries;
        });
      }
    }
  }, [vodEntries?.metadata?.offset, vodEntries?.data.documents, isOrderDialogOpen, setModel, setPristineModel]);

  React.useEffect(() => {
    setHighestOrder(maxBy(entries, 'order')?.order);
  }, [entries]);

  React.useEffect(() => {
    setLazyLoading(isFetchingEntries);
  }, [isFetchingEntries]);

  const onLazyLoad = React.useCallback(() => {
    setLazyLoading(true);
    setTimeout(() => {
      if ((vodEntries?.metadata?.offset || 0) < (vodEntries?.metadata?.totalCount || 0)) {
        setOffset((vodEntries?.metadata?.offset || 0) + DEFAULT_ROWS_PER_PAGE);
      }
    }, 500);
    setLazyLoading(false);
  }, [vodEntries?.metadata?.offset, vodEntries?.metadata?.totalCount]);

  const handleOrderUpdate = React.useCallback(async () => {
    const reorderedVodCollectionEntries = model.orderElements;

    if (!reorderedVodCollectionEntries) return false;

    try {
      const result: any = await updateVodOrderBulk({
        vodCategoryId,
        orderElements: reorderedVodCollectionEntries,
      });

      if ('error' in result) Toast.error('error', `${result.error.data.message}.`);
      setIsOrderDialogOpen(false);
      handleHasReordered(true);
      Toast.success('Success', 'Order Saved', 8000);
      return result;
    } catch (e) {
      Toast.error('Error', 'Failed to reorder VOD Collection Entries. Please try again');
      setIsOrderDialogOpen(false);
      handleHasReordered(false);
    }
  }, [model.orderElements, updateVodOrderBulk, vodCategoryId, setIsOrderDialogOpen, handleHasReordered]);

  return (
    <Dialog isOpen={isOrderDialogOpen} onClose={() => setIsOrderDialogOpen(false)} width='100%' height='100%'>
      <Template label='header'>
        <Heading level='h2'>Reorder Titles</Heading>
      </Template>
      <Template label='body'>
        <Table
          loading={false}
          fixedHeader={true}
          dropKeys={['example']}
          dragKey='example'
          draggable={true}
          wrapContent={true}
          onDrop={onDrop}
          onLazyLoad={onLazyLoad}
          lazyLoading={lazyLoading}
          id='titles-list-reorder'
          cols={[
            {
              label: 'Order',
              colWidth: '1.5rem',
              transform: (row, _col, index) => (
                <Cluster>
                  <Popover
                    appendToBody={true}
                    manualTrigger={true}
                    visible={orderPopoverOpen[index]}
                    onClickOutside={() => setOrderPopoverOpen({})}
                  >
                    <Template label='trigger'>
                      <Click color='primary' onClick={() => setOrderPopoverOpen({[index]: true})}>
                        {row.order}
                      </Click>
                    </Template>
                    <Template label='popover'>
                      <OrderForm
                        maxValue={highestOrder}
                        visible={orderPopoverOpen[index]}
                        onCancel={() => setOrderPopoverOpen({})}
                        value={row}
                        onSave={val => {
                          const oldRowIndex = entries.findIndex(e => {
                            return e.order === val.order;
                          });
                          onDrop(null, null, index, oldRowIndex);
                          setOrderPopoverOpen({});
                        }}
                      />
                    </Template>
                  </Popover>
                </Cluster>
              ),
            },
            {
              label: 'Title',
              transform: row => (
                <Cluster wrap={false} space='small' align='center'>
                  <ImageWrapper
                    backgroundColor='black'
                    width='2.5rem'
                    height='3.65rem'
                    src={row.thumbnail}
                    alt={row.name}
                  />
                  {row.state === 'warning' && (
                    <Popover appendToBody={true} trigger='mouseenter'>
                      <Template label='trigger'>
                        <Icon icon='warning' color='warning' />
                      </Template>
                      <Template label='popover'>
                        <Box paddingX='small' paddingY='xxsmall' background='charcoal'>
                          <Stack space='xxxsmall'>
                            <Icon icon='warning' space='small' color='warning'>
                              Warning Text Here
                            </Icon>
                            <Icon icon='warning' space='small' color='warning'>
                              Some other warning here
                            </Icon>
                            <Icon icon='warning' space='small' color='warning'>
                              One last warning
                            </Icon>
                          </Stack>
                        </Box>
                      </Template>
                    </Popover>
                  )}
                  {row.name}
                </Cluster>
              ),
              colMinWidth: '12.5rem',
            },
            {
              label: 'Collections',
              transform: row => row.collections,
              colMinWidth: '5.9375rem',
            },
            {
              label: 'Series Type',
              transform: row => row.seriesType,
              colMinWidth: '8.9375rem',
            },
            {
              label: 'Genre',
              colMinWidth: '7.5rem',
              transform: row => row.genre,
            },
            {
              label: '# of S',
              colMinWidth: '5.9375rem',
              transform: row => row.seriesCount,
            },
            {
              label: '# of E',
              colMinWidth: '5.9375rem',
              transform: row => row.episodeCount,
            },
            {
              label: 'Availability Window',
              colMinWidth: '13.5rem',
              colWidth: '14rem',
              transform: row =>
                row?.availabilityWindows?.map(a => {
                  const dateEnd = new Date(a.endDate);
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);

                  if (dateEnd >= today) {
                    return (
                      <Cluster key={`avod-${a.id}`} wrap={true} justify='space-around'>
                        <Box paddingY='xxxxsmall'>
                          <Center>{a.startDate}</Center>
                        </Box>
                        <Box paddingY='xxxxsmall' paddingX='none'>
                          <Center>-</Center>
                        </Box>
                        <Box paddingY='xxxxsmall'>
                          <Center>{a.endDate}</Center>
                        </Box>
                      </Cluster>
                    );
                  }
                }),
            },
          ]}
          rows={entries}
        >
          <Template label='loading'>
            <Cluster space='small' align='center'>
              <Spinner />
              <Paragraph>Loading Clips</Paragraph>
            </Cluster>
          </Template>
          <Template label='empty'>
            <Notification type='warning'>There are no clips currently available.</Notification>
          </Template>
        </Table>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={() => setIsOrderDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              state={vodCategoryId && formState.isValid && formState.isDirty ? '' : isLoading ? 'thinking' : 'disabled'}
              type='primary'
              onClick={() => handleOrderUpdate()}
            >
              Save Reorder
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
};
